import React from 'react';
import ThemesImg from '../img/ui-ux.png';
import HeadlessImg from '../img/coading.png';
import AppsImg from '../img/appdevelop.png';

const BuildForShopify = () => {
  return (
    <section className='homesection'>
      <div className='banner-section'>
        <div className='banner-section-inner'>
          <h4 className='subtitle'>Want to achieve Build for Shopify ?</h4>
          <h2 className='title'>SureCust: Hire us for Expert advice for Build for Shopify</h2>
          <p className='desc'>Need help building Build for Shopify apps? SureCust provides tailored solutions to ensure your app meets Shopify's requirements and stands out in the marketplace.</p>
        </div>
      </div>
      <div className='service-section'>
        <div className='service-section-container'>
          <div className='service-section-grid'>
            <div className='service-section-grid-item'>
              <div className='service-section-grid-item-inner'>
                <h3 className='title'>Apps</h3>
                <p className='desc'>
                  Add unique features and integrations to enhance your store and streamline operations.
                </p>
                <a href="mailto:approvemate@gmail.com" className="button">Discuss Your Needs</a>
                <div className='service-img'>
                  <img src={AppsImg} alt="Themes" width="400"/>
                </div>
              </div>
            </div>
            <div className='service-section-grid-item'>
              <div className='service-section-grid-item-inner highlight'>
                <h3 className='title'>Themes</h3>
                <p className='desc'>
                  Design stunning, responsive themes that showcase your brand and engage customers.
                </p>
                <a href="mailto:approvemate@gmail.com" className="button active">Discuss Your Needs</a>
                <div className='service-img'>
                  <img src={ThemesImg} alt="Themes" width="400"/>
                </div>
              </div>
            </div>
            <div className='service-section-grid-item'>
              <div className='service-section-grid-item-inner'>
                <h3 className='title'>Headless</h3>
                <p className='desc'>
                Gain flexibility and speed with a decoupled front-end and back-end for your e-commerce store.
                </p>
                <a href="mailto:approvemate@gmail.com" className="button">Discuss Your Needs</a>
                <div className='service-img'>
                  <img src={HeadlessImg} alt="Themes" width="400"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BuildForShopify;