import { useState, useCallback, useEffect } from 'react';

import '../form.css';

const ApiCheck = () => {

	const [emailAlready, setEmailAlready] = useState('Email Already Taken! Try a different Email or Login');
	const [loginLabel, setLoginLabel] = useState("Sign In");
	const [loginPosition, setLoginPosition] = useState("bottom");
	const [loginPrefix, setLoginPrefix] = useState("Already have an account?");
	const [type, setType] = useState("wholesale");
	const [submitLabel, setSubmitLabel] = useState("Submit");
	const [formFields, setFormFields] = useState([]);
	const [formLoading, setFormLoading] = useState(true);
	const [successMessage, setSuccessMessage] = useState("Your application is successfully submitted, we will check your details and send you activation email soon. It can take few days");

  function rearrangeArrayByPosition(array) {
		array.sort((a, b) => a.position - b.position);
		return array;
	}

  const fetchData = async () => {
    try {
      const response = await fetch('https://approve-mate-stage-gtrls.ondigitalocean.app/api/headlessforms', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': '40ac06fd4e63a728052f17f747672923575da58ab388f2403981ec06e38c0aed',
        },
        body: JSON.stringify({
          shop: 'test-store-online-status.myshopify.com',
          formid: '17260768324412',
        }),
      });

      if (!response.ok) {
        throw new Error('Invalid API key or Invalid Shop or Invalid Form ID');
      }

      const result = await response.json();
      if( result.data == 'empty' ){}else{
        if( result.data.length > 0 ){
          var formData = result.data[0];
          var formFields = JSON.parse(formData.settings);
          setFormFields(rearrangeArrayByPosition(formFields));
          setType(formData.type);
          if( formData.loginposition ){
            if( formData.loginposition == 'top' ){
              setLoginPosition('top');
            }
          }
          if( formData.emailalready ){
            if( formData.emailalready !== null ){
              setEmailAlready(formData.emailalready);
            }
          }
          if( formData.loginprefix ){
            if( formData.loginprefix !== null ){
              setLoginPrefix(formData.loginprefix);
            }
          }
          if( formData.login ){
            if( formData.login !== null ){
              setLoginLabel(formData.login);
            }
          }
          if( formData.submit ){
            if( formData.submit !== null ){
              setSubmitLabel(formData.submit);
            }
          }
          if( formData.success ){
            if( formData.success !== null ){
              setSuccessMessage(formData.success);
            }
          }
        }
      }
      setFormLoading(false);
    } catch (error) {
      console.log(error);
      setFormLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log(formFields);

  return (
    <section>
      <div className='onst-registration-form SureCust-App'>
        <div className='onst-registration-form-container onst-big-form SureCust-App'>
          <h2 className="title">Wholesale Application</h2>
          <p className="subtitle">Submit the application by filling in this form.</p>
          <div class="onst-custom-form-box SureCust-App">
            {formLoading?<>
              <div className="onst-custom-form-box-loader"><span className="onst-loading-icon"></span></div>
            </>:<>
              <div className="onst-registration-form-container-box SureCust-App">
                <form method="post" className="onst-custom-form onst-show-labels SureCust-App" action="/account" autoComplete="on" acceptCharset="UTF-8">
                  <input type="hidden" name="form_type" value="create_customer"/>
                  <div className="onst-register-form-fields SureCust-App">
                    {formFields.map((data,index) => {
                        console.log(data);
                        console.log(index);
                        return (
                          <div key={index} class="onst-field onst-size-">

                          </div>
                        );
                    })}
                  </div>
                </form>
              </div>
            </>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApiCheck;