import React from 'react';

const Footer = () => {
  return (
    <footer>
        <div className="surecust-footer-box">
          <div className="surecust-container">
            <p className='copyright-text'>Copyright © 2024 SureCust. All Rights Reserved.</p>
          </div>
        </div>
    </footer>
  );
};

export default Footer;