// components/Accordion.js
import React, { useState, useRef, useEffect } from 'react';
import DownIcon from '../img/chevron-down.svg';

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef(null);

  useEffect(() => {
    setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
  }, [isOpen]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={toggleAccordion}>
        {title}
        <span className="accordion-icon"><img src={DownIcon} alt=""/></span>
      </div>
      <div
        ref={contentRef}
        className="accordion-content"
        style={{ height }}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
