// pages/ApproveCustomer.js
import React from 'react';
import { NavLink  } from 'react-router-dom';
import Accordion from '../components/Accordion';

import oneOneHelp from '../img/approve/1-1.jpg';
import oneTwoHelp from '../img/approve/1-2.jpg';
import oneThreeHelp from '../img/approve/1-3.jpg';
import oneFourHelp from '../img/approve/1-4.jpg';
import oneFourFiveHelp from '../img/approve/1-4.5.jpg';
import oneFiveHelp from '../img/approve/1-5.jpg';
import oneSixHelp from '../img/approve/1-6.jpg';
import oneSevenHelp from '../img/approve/1-7.jpg';
import oneEightHelp from '../img/approve/1-8.jpg';
import oneNineHelp from '../img/approve/1-9.jpg';
import oneTenHelp from '../img/approve/1-10.jpg';
import oneElevenHelp from '../img/approve/1-11.jpg';
import oneTwelveHelp from '../img/approve/1-12.jpg';
import oneThirteenHelp from '../img/approve/1-13.jpg';
import oneFourteenHelp from '../img/approve/1-14.jpg';
import oneFifteenHelp from '../img/approve/1-15.jpg';
import oneSixteenHelp from '../img/approve/1-16.jpg';
import oneSeventeenHelp from '../img/approve/1-17.jpg';
import oneEighteenHelp from '../img/approve/1-18.jpg';
import oneNineteenHelp from '../img/approve/1-19.jpg';
import oneTwentyHelp from '../img/approve/1-20.jpg';

const ApproveCustomer = () => {
  return (
    <section>
      <div className='breadcrumb-section'>
          <div className="surecust-container">
              <div className="surecust-navigation-list">
                  <ul className="surecust-menu-link-list">
                      <li className="surecust-menu-link-item">
                        <a 
                          href="https://apps.shopify.com/approve-mate"
                          className="surecust-menu-link"
                          target='_blank'
                        >
                          Install
                        </a>
                      </li>
                      <li className="surecust-menu-link-item">
                        <NavLink
                          to="/approvecustomer/quicksetup"
                          className="surecust-menu-link"
                        >
                          Advance setup guide
                        </NavLink>
                      </li>
                      <li className="surecust-menu-link-item">
                        <NavLink
                          to="/approvecustomer/videos"
                          className="surecust-menu-link"
                        >
                          Video tutorials
                        </NavLink>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      <div className='accordion-section'>
        <div className="surecust-container">
          <div className="accordion-item-box">
            <Accordion title="Set up the default customer registration form.">
              <div className='accordion-item-box-inner'>
                <div className="Polaris-Box">
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Go to <b>Online Store &gt; Themes &gt; Customize: </b>
                      </p>
                    </li>
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you can see Theme customize is open and you need to select register page from Classic customer accounts in page selector
                      </p>
                    </li>
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Classic customer accounts:</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneOneHelp}/>
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Customer register:</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneTwoHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to click on <b>Add Section</b> in <b>Template</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneThreeHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Apps &gt; SureCust Register</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneFourHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to click on <b>Create account</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneFourFiveHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Here you can edit <b>form title, subtitle, labels</b> and <b>etc.</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneFiveHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Finally, you can <b>disable your old form</b> and save customization.
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneSixHelp} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion>
            <Accordion title="Set up the default wholesale application form.">
              <div className='accordion-item-box-inner'>
                <div className="Polaris-Box">
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Go to <b>Online Store &gt; Themes &gt; Customize:</b>
                      </p>
                    </li>
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you can see Theme customize is open and you need to select register page from Classic customer accounts in page selector
                      </p>
                    </li>
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Classic customer accounts:</b>
                      </p>
                    </li>
                  </ul>

                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneOneHelp}/>
                      </div>
                    </div>
                  </div>

                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Customer register:</b>
                      </p>
                    </li>
                  </ul>

                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneTwoHelp} />
                      </div>
                    </div>
                  </div>

                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to click on <b>Add Section</b> in <b>Template</b>
                      </p>
                    </li>
                  </ul>

                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneThreeHelp} />
                      </div>
                    </div>
                  </div>

                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Apps &gt; SureCust Wholesale Form</b>
                      </p>
                    </li>
                  </ul>

                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneSevenHelp} />
                      </div>
                    </div>
                  </div>

                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to click on <b>Create Wholesale account</b>
                      </p>
                    </li>
                  </ul>

                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneEightHelp} />
                      </div>
                    </div>
                  </div>

                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Here you can edit <b>form title, subtitle, success message</b> and <b>etc.</b>
                      </p>
                    </li>
                  </ul>

                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneNineHelp} />
                      </div>
                    </div>
                  </div>

                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now, you can <b>disable your old form</b> and save customization.
                      </p>
                    </li>
                  </ul>

                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneSixHelp} />
                      </div>
                    </div>
                  </div>

                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        For editing field labels, placeholders, and sizes, go to: <b>SureCust App &gt; Settings &gt; Default wholesale form fields</b>
                      </p>
                    </li>
                  </ul>

                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneTenHelp} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion>
            <Accordion title="Set up the #custom wholesale application form.">
              <div className='accordion-item-box-inner'>
                <div className="Polaris-Box">
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Go to: <b>SureCust App &gt; Custom forms &gt; </b> and click on <b>Create form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneElevenHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now create <b>Wholesale registration form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneTwelveHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        After creating custom form click on <b>Save</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneThirteenHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        After save you need to copy the <b>form ID.</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneFourteenHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now go to: <b>Online Store &gt; Themes &gt; Customize: </b>
                      </p>
                    </li>
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you can see Theme customize is open and you need to select register page from Classic customer accounts in page selector
                      </p>
                    </li>
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Classic customer accounts:</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneOneHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Customer register:</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneTwoHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to click on <b>Add Section</b> in <b>Template</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneThreeHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Apps &gt; SureCust Custom Form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneFifteenHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to click on <b>Custom Form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneSixteenHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Here you need to add <b>Form ID</b> that you copied in previous steps.
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneSeventeenHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Finally, you can <b>disable your old form</b> and save customization.
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneSixHelp} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion>
            <Accordion title="Set up the #custom contact form.">
              <div className='accordion-item-box-inner'>
                <div className="Polaris-Box">
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Go to: <b>SureCust App &gt; Custom forms &gt; </b> and click on <b>Create form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneElevenHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now create <b>Contact form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneEighteenHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        After creating custom form click on <b>Save</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneNineteenHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        After save you need to copy the <b>form ID.</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneTwentyHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to go on the destination page where you can add the <b>Contact form.</b>
                      </p>
                    </li>
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to click on <b>Add Section</b> in <b>Template</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneThreeHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Select <b>Apps &gt; SureCust Custom Form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneFifteenHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Now you need to click on <b>Custom Form</b>
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneSixteenHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Here you need to add <b>Form ID</b> that you copy in previous steps.
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneSeventeenHelp} />
                      </div>
                    </div>
                  </div>
                  <ul className="Polaris-List Polaris-List--spacingLoose">
                    <li className="Polaris-List__Item">
                      <p className="Polaris-Text--root Polaris-Text--bodyMd">
                        Finally, you can <b>disable your old form</b> and save customization.
                      </p>
                    </li>
                  </ul>
                  <div className="Polaris-Box">
                    <div className="sureCustImage">
                      <div className="Polaris-Box">
                        <img alt="SureCust" src={oneSixHelp} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion>
           </div>
        </div>
      </div>
    </section>
  );
};

export default ApproveCustomer;