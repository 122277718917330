// pages/ApproveCustomer.js
import React from 'react';
import { NavLink  } from 'react-router-dom';

import contactFormImg from '../img/surecust/contact-form.jpg';
import defaultWholesaleImg from '../img/surecust/default-wholesale.jpg';
import customWholesaleImg from '../img/surecust/custom-wholesale.jpg';
import customerRegistrationImg from '../img/surecust/customer-registration.jpg';

const VideoApproveCustomer = () => {
  return (
    <section>
      <div className='breadcrumb-section'>
          <div className="surecust-container">
              <div className="surecust-navigation-list">
                  <ul className="surecust-menu-link-list">
                      <li className="surecust-menu-link-item">
                        <a 
                          href="https://apps.shopify.com/approve-mate"
                          className="surecust-menu-link"
                          target='_blank'
                        >
                          Install
                        </a>
                      </li>
                      <li className="surecust-menu-link-item">
                        <NavLink
                          to="/approvecustomer/quicksetup"
                          className="surecust-menu-link"
                        >
                          Quick setup guide
                        </NavLink>
                      </li>
                      <li className="surecust-menu-link-item">
                        <NavLink
                          to="/approvecustomer/videos"
                          className="surecust-menu-link"
                        >
                          Video tutorials
                        </NavLink>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      <div className='video-grid-section'>
        <div className="surecust-container">
            <div className='video-grid-list-box'>
              <div className='video-grid-list'>
                <div className='video-grid-list-item'>
                  <div className='video-img-box'>
                    <a href="https://youtu.be/tif50YOCoMs" target='_blank'><img src={customerRegistrationImg} alt=""/></a>
                  </div>
                  <div className='video-contenr-box'>
                    <label>Set up the default customer registration form.</label>
                    <p className='desc'>After installing the SureCust app, follow the steps shown in the video to add the default customer registration form in your theme.</p>
                    <a href="https://youtu.be/tif50YOCoMs" target='_blank' className='button'>Watch now</a>
                  </div>
                </div>
                <div className='video-grid-list-item'>
                  <div className='video-img-box'>
                    <a href="https://youtu.be/yV27I1yU1k8" target='_blank'><img src={defaultWholesaleImg} alt=""/></a>
                  </div>
                  <div className='video-contenr-box'>
                    <label>Set up the default wholesale application form.</label>
                    <p className='desc'>After installing the SureCust app, follow the steps shown in the video to add the default wholesale application form in your theme.</p>
                    <a href="https://youtu.be/yV27I1yU1k8" target='_blank' className='button'>Watch now</a>
                  </div>
                </div>
                <div className='video-grid-list-item'>
                  <div className='video-img-box'>
                    <a href="https://youtu.be/ibfWyFRAY2A" target='_blank'><img src={customWholesaleImg} alt=""/></a>
                  </div>
                  <div className='video-contenr-box'>
                    <label>Set up the #custom wholesale application form.</label>
                    <p className='desc'>After installing the SureCust app, follow the steps shown in the video to add the custom wholesale application form in your theme.</p>
                    <a href="https://youtu.be/ibfWyFRAY2A" target='_blank' className='button'>Watch now</a>
                  </div>
                </div>
                <div className='video-grid-list-item'>
                  <div className='video-img-box'>
                    <a href="https://youtu.be/JQQ2VBl9at0" target='_blank'><img src={contactFormImg} alt=""/></a>
                  </div>
                  <div className='video-contenr-box'>
                    <label>Set up the #custom contact us / support form.</label>
                    <p className='desc'>After installing the SureCust app, follow the steps shown in the video to add the custom contact form in your theme.</p>
                    <a href="https://youtu.be/JQQ2VBl9at0" target='_blank' className='button'>Watch now</a>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default VideoApproveCustomer;